import React from "react";

import Image from "react-bootstrap/Image";
import facebook from "../icons/facebook.svg";
import instagram from "../icons/instagram.svg";
import home_icon from "../icons/home_icon.svg";
import locations_icon from "../icons/location_icon.svg";
import phone_icon from "../icons/phone_icon.svg";
import email_icon from "../icons/email_icon.svg";
import { Link } from "react-router-dom";

import TOS_page from "../pages/TOS";

// import logoBlack from "../images/originals/noWordLogo.png";
import logoBlack from "../icons/noWordLogo.webp";

///////  REACT-BOOTSTRAP STYLING ///////////
import "bootstrap/dist/css/bootstrap.min.css";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="footer">
      <div className="footer_left">
        <div className="footer_title mb-3">
          <Image className="logo_footer" src={logoBlack} alt="logo" />
          <h2>GoWithGIG</h2>
        </div>
        <p>
          © {currentYear} GoWithGig: Revolutionizing rideshare car rentals with
          convenience and reliability. We’re committed to providing the best
          experience for both drivers and passengers. Drive with us, ride with
          us, and explore your world with ease.
        </p>
        <div className="connect_box">
          <h3 className="mb-3 mt-2">Connect!</h3>
          <div className="connect_box_links">
            <a
              href="https://www.facebook.com/GoWithGig.Atlanta"
              className="footer_link socialMedia_link"
            >
              <Image
                src={facebook}
                className="footer_icon socialMedia_icon"
                alt="facebook"
              />
            </a>
            <a
              href="https://www.instagram.com/gowithgig/"
              className="footer_link socialMedia_link instagram_link"
            >
              <Image
                src={instagram}
                className="footer_icon socialMedia_icon"
                alt="instagram"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="footer_right">
        <div className="quickLink_list_container mb-4">
          <h3 className="mb-3">Quick Links</h3>
          <ul className="quickLink_list">
            <li>
              <a href="/" className="footer_link footer_quickLinks">
                <Image
                  className="footer_icon quickLink_img"
                  src={home_icon}
                  alt="home icon"
                />
                Home
              </a>
            </li>
            <li>
              <a href="#" className="footer_link footer_quickLinks">
                <a
                  href="https://maps.app.goo.gl/1LFzqhHTRoqRSjaZ8"
                  target="_blank"
                  class="button"
                >
                  <Image
                    className="footer_icon quickLink_img"
                    src={locations_icon}
                    alt="locations icon"
                  />
                </a>
                Locations
              </a>
            </li>
            <li>
              <Link
                to="/terms-of-service"
                className="footer_link footer_quickLinks"
              >
                Terms of Service
              </Link>
            </li>
            <li>
              <Link
                to="/privacy-policy"
                className="footer_link footer_quickLinks"
              >
                Privacy Policy
              </Link>
            </li>
          </ul>
        </div>
        <div className="contact_box">
          <h3 className="mb-3">Contact</h3>
          <div className="contactBox_links_container">
            <div className="contactBox_links">
              <a href="tel:+16788091597">
                <Image
                  className="contact_img phone_img"
                  src={phone_icon}
                  alt="locations icon"
                />
              </a>
              678-809-1597
            </div>
            <div className="contactBox_links">
              <a href="mailto:info@gowithgigcar.com">
                <Image
                  className="contact_img"
                  src={email_icon}
                  alt="email icon"
                />
              </a>
              info@gowithgigcar.com
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
